.NewsWrapper {
    padding: 0px !important;
    margin-bottom: 32px;
}

.mySwiper {
    width: 100%;
    height: 80vh;
}

.swiper-slide {
    width: 100% !important;
    height: 100% !important;
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: 100% 100%;
    border-radius: 0px;
    box-shadow: inset 0px -200px 30px -20px rgba(0, 0, 0, 0.8);
}

.mySwiperOverlay {
    padding: 16px;
    width: 100%;
    height: 50%;
    display: flex;
    align-items:center;
    justify-content: flex-end;
    flex-direction: column;
    border-radius: 16px;
    gap: 8px;
}

.mySwiperCategory {
    background-color: var(--third);
    padding: 4px 16px;
    border-radius: 4px;
    color: var(--secondary);
    font-weight: bold !important;
    text-transform: capitalize;
}

.mySwiperPublished {
    color: var(--white) !important;
}

.mySwiperTitle {
    color: var(--white) !important;
    text-align: center;
}

.mySwiperDesc {
    color: var(--white) !important;
    opacity: 0.7;
    text-align: center;
}

.mySwiperReadMore{
    background-color: var(--third);
    width: 80%;
    padding: 8px;
    border-radius: 4px;
    color: var(--secondary);
    font-weight: bold !important;
    text-decoration: none;
}