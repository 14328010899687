.testimonialWrapper {
    padding: 100px 0px;
}
.testimonialTitleContainer{
    color: var(--secondary);
    font-weight: bold !important;
    background-color: var(--third);
    text-align: center;
    border-radius: 4px;
    padding: 4px;
}

.testimonialWrapper .testimonialAvatarName {
    display: flex;
}

.testimonialWrapper .testimonialAvatarName img {
    border-radius: 8px;
    width: 80px;
    height: 80px;
}

.testimonialWrapper .testimonialAvatarName .testimonialName {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.testimonialWrapper .testimonialAvatarName .testimonialName h6 {
    font-weight: bold;
    color: var(--fourth);
    padding: 8px 0px;
}

.testimonialWrapper .testimonialBodyContainer {
    color: var(--third);
}

.grid-wrapper {
    width: 100%;
    padding: 16px;
    overflow-x: auto;
    overflow-y: hidden;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: 250px;
    grid-auto-flow: dense;
}

.grid-wrapper .default {
    grid-column: span 1;
    padding: 16px;
    text-align: left;
}

.grid-wrapper .default .testimonialBodyContainer p {
    padding: 16px 0px;
}

.grid-wrapper .wide {
    grid-column: span 2;
    padding: 16px;
    text-align: left;
}

.grid-wrapper .wide .testimonialBodyContainer p {
    padding: 16px 0px;
}

.grid-wrapper .tall {
    grid-row: span 2;
    padding: 16px;
}

.grid-wrapper .tall .testimonialBodyContainer p {
    text-align: center;
    padding: 32px 0px;
}
