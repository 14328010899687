:root {
    --primary: #652D91;
    --secondary: #1757B9;
    --third: #FFBF53;
    --fourth: #00BEAC;
    --black: #000;
    --gray: #3e3e3e;
    --white: #fff;
}

@font-face {
    font-family: Popins;
    src: url(./assets/fonts/Poppins-Regular.ttf);
}

* {
    margin: 0;
    padding: 0;
    font-family: 'Popins';
    
}


body {
    overflow-x: hidden;
    background-color: var(--primary) !important;
}

body::-webkit-scrollbar {
    display: none;
}

p {
    margin: 0;
    padding: 0;
}