.PremierleagueGoalsContent{
    min-width: 450px !important;
    height: 220px;
    background-color: var(--secondary);
    border-radius: 24px;
}

.PremierleagueGoalsContentOverlay{
    background-image: url(../../assets/images/premierleague-logo.png);
    background-position: 50% 50%;
    background-size: 50% 100%;
    background-repeat: no-repeat;
    height: 100%;
}

.PremierleagueGoalsContentOverlayContent{
    background-color: rgba(0, 0, 0, 0.8);
    height: 100%;
    border-radius: 24px;
    color: var(--white);
    /* padding: 16px; */
    display: flex;
    justify-content: space-between;
}