.footerWrapper {
    width: 100%;
    background-color: var(--primary);
}

.footerWrapper .footerBootstrapContainer {
    margin: 0px 0px;
    max-width: 100% !important;
}

@media screen and (min-width: 768px) {
    .footerWrapper .footerBootstrapContainer {
        margin: initial;
        max-width: initial !important;
    }
}

.footerWrapper .footerContainer {
    width: 100%;
    height: 30vh !important;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    color: var(--white);
}

@media screen and (max-width: 1199.5px) {
    .footerWrapper .footerContainer {
        height: auto !important;
    }
}

@media screen and (max-width: 767.5px) {
    .footerWrapper .footerContainer {
        justify-content: space-around;
    }
}

.footerWrapper .footerContainer .footerLinksContainer {
    margin-top: 32px !important;
    text-align: center;
}

@media screen and (min-width: 1200px) {
    .footerWrapper .footerContainer .footerLinksContainer {
        margin-top: initial;
    }
}

.footerWrapper .footerContainer .footerLinksContainer .footerLink {
    cursor: pointer;
    color: var(--white);
    text-decoration: none;
}

.footerWrapper .footerContainer .footerLinksContainer .footerLink div p {
    margin-bottom: 4px !important;
}

.footerWrapper .copyrightContainer {
    color: var(--white);
    text-align: center;
}

.footerWrapper .copyrightContainer p {
    margin-top: 72px !important;
    padding: 8px 0px;
    font-size: 1vw;
}

.footerTitles {
    font-size: 1.2vw;
    color: var(--third);
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 8px;
}

@media screen and (max-width: 800px) {
    .footerTitles {
        font-size: 2vw;
    }
}

@media screen and (max-width: 500px) {
    .footerTitles {
        font-size: 3vw;
    }
}

.footerBody {
    height: 120px;
}
.footerBodyLink{
    height: 70px;
    display: flex;
    justify-content: center;
    gap: 32px;
    
}

@media screen and (max-width: 1199.5px) {
    .footerBody {
        height: auto;
    }
}

@media screen and (max-width: 375px) {
    .footerBody {
        font-size: 3.5vw;
    }
}
