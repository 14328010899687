.nbaRankingText h4 {
    color: var(--secondary);
    font-weight: bold !important;
    background-color: var(--third);
    text-align: center;
    border-radius: 4px;
    padding: 4px;
}

.stateContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    width: 25vw;
    
}
@media screen and (max-width:767px) {
    .stateContainer{
        flex-direction: row;
    }
}
.stateSelected{
    width: 100%;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    background-color: var(--secondary);
    color: var(--white);
    padding: 4px 24px;
    border-radius: 8px;
}
.stateNotSelected{
    padding: 4px 24px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: var(--white);
}

.nbaRankingsContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex-wrap: wrap;
    margin-top: 24px;
}
.nbaRankings{
    width: 250px !important;
    background-color: var(--secondary);
    border-radius: 16px;
    padding: 16px;
}

.nbaRankingsContentContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nbaRankingNameContainer{
   font-weight: bolder;
   color: var(--third);
}

.nbaRankingWinLossContainer{
    color: var(--fourth);
    font-weight: bold !important;
}