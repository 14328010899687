.NhlLeadersText h4 {
    color: var(--secondary);
    font-weight: bold !important;
    /* margin-bottom: 48px; */
}

.NhlLeadersDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 48px;
}

.NhlLeadersImgNameDetails {
    display: flex;
    align-items: center;
}

.NhlLeadersValueContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--white);
    background-color: var(--secondary);
    width: 100px;
    height: 100px;
    border-radius: 100px;
}

.NhlLeadersValueContainer h2 {
    font-weight: bold;
    margin: 0;
}

.NhlLeadersValueContainer h6 {
    opacity: 0.8;
    margin: 0;
}

.NhlLeadersContentContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0px 24px;
    border: 1px solid var(--fourth);
    margin-bottom: 16px;
    border-radius: 24px;
}

.NhlLeadersContent {
    background-color: rgba(35, 78, 136, 0.2);
    cursor: pointer;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.NhlLeadersTextContainer {
    border-radius: 100px;
    transition: background-color 0.5s ease;
    padding: 2px 6px;
}

.NhlLeadersContent div {
    display: flex;
}

.NhlLeadersContent div p {
    margin: 0;
    transition: color 0.5s ease;
}

.NhlLeadersContent .NhlLeadersTextContainer {
    background-color: var(--primary);
}

.NhlLeadersContent .NhlLeadersTextContainer p {
    color: var(--white) !important;
}