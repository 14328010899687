.withdrawSubmitButton {
    border-radius: 8px !important;
    background-color: var(--primary) !important;
    padding: 8px 0px !important;
    color: var(--white) !important;
    font-weight: bold;
    border: none !important;
    width: 100%;
}

.successMessageContainer {
    width: 100%;
    padding: 8px 0px;
}

.successMessageContainer p {
    padding: 8px;
    color: #228B22;
    font-size: 18px;
    font-weight: 600;
}

.errorMessageContainer {
    width: 100%;
}

.errorMessageContainer p {
    padding: 8px;
    color: red;
    font-size: 18px;
    font-weight: 600;
}

.maxButton,
.maxButton:hover,
.maxButton:focus {
    background-color: var(--primary) !important;
    border: none !important;
    border-radius: 0px 8px 8px 0px !important;
}

.withdrawInput,
.withdrawInput:focus,
.withdrawInput:hover,
.withdrawInputD {
    height: 45px;
    border-radius: 8px;
}

.withdrawSentence {
    color: var(--purple);
    font-weight: bold;
}

.withdrawLabel {
    font-size: 16px;
    color: var(--white);
    font-weight: bold;
}

@media screen and (max-width:992px) {
    .withdrawImageContainer {
        display: none;
    }
}