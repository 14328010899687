.NbaDraftsText h4 {
    color: var(--secondary);
    font-weight: bold !important;
    background-color: var(--third);
    text-align: center;
    border-radius: 4px;
    padding: 4px;
}
.accordion-item{
    margin-bottom: 8px !important;
}
.accordion-item:last-of-type .accordion-button.collapsed{
    background-color: var(--secondary) !important;
    /* margin-top: 8px; */
}
.accordion-body{
    padding: 0px !important;
}
.nbaAccordionBody{
    background-color: var(--primary);
    padding: 8px;
    color: var(--fourth);
    font-weight: bold !important;
    border-bottom: 1px solid var(--third);
}
.nbaAccordionBody:last-child{
    border-bottom: none !important;
}
.nbaAccordionBody p {
    color: var(--third);
    font-weight: bold !important;
}

.accordion-item {
    border: none;
}

.accordion-item .accordion-button{
    padding: 6px;
    cursor: pointer;
    outline: none;
    color: var(--se);
    font-weight: bold;
}
.accordion-button:not(.collapsed){
    background-color: var(--secondary);
    outline: none;
}
.accordion-button:focus {
    box-shadow: none !important;
    outline: none !important;
}
.accordion-button:not(.collapsed)::after{
    background-image: url(../../assets/images/minus.svg);
}

.accordion-button::after{
    background-image: url(../../assets/images/plus.svg);
    width: 14px;
    height: 14px;
    background-size:14px;
}