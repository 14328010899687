.MlsLeagueWrapper{
    padding:32px 0px
}
.MlsLeagueText h4 {
    color: var(--primary);
    font-weight: bold !important;
    border-bottom: 1px solid var(--primary);
}
.MlsLeagueData{
    border: 1px solid var(--white);
    padding: 8px;
    border-radius: 16px;
}

.MlsLeagueDataContentFirst{
    background-color: var(--primary);
    width: 400px;
    color: var(--white) !important;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}