.highlightsWrapper {
    height: auto;
    padding: 20px 0px;
    overflow-x: auto;
    width: 100% !important;
}

.highlightsContentContainer {
    display: flex;
    align-items: center;
    margin: 0px 16px;
    gap: 8px;
    width: 100%;
}

.highlightsContent {
    width: 100% !important;
    border-radius: 0px !important;
    display: flex;
    flex-direction: row;

}